import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Grid, Icon } from 'semantic-ui-react';
import { useIntl } from 'react-intl';

import HW_PRODUCT_MESSAGES from '~/common/locales/HardwareProducts';
import { TaskType } from '~/common/types';
import { TASK_STATUSES } from '~/common/constants';
import HardwareStatusTaskDetails from '~/app/components/TaskSummary/HardwareStatusTaskDetails';
import { getGatewayWithStatus, getSensorWithStatus } from '~/app/actions/HardwareActions';
import Tag from '~/common/components/Tag';
import '~/app/styles/components/TaskSummary.css';

const mergeStatusNowToHardware = (hardwareStatusNow, hardware) => {
  if (!hardwareStatusNow?.status) return hardware;

  return {
    ...hardware,
    statusNow: {
      ...hardwareStatusNow.status.signal,
      isOnline: hardwareStatusNow.status.isOnline,
    },
  };
};

const JobTaskRow = ({ task }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);

  const { hardware, type } = task;
  let isLoadingData = false;
  const {
    sensorId,
    gatewayId,
  } = hardware || {};

  const sensorState = useSelector(state => state.hardware.sensors?.byId[sensorId]);
  const gatewayState = useSelector(state => state.hardware.gateways?.byId[gatewayId]);
  const userState = useSelector(state => state.users.current);

  const fetchHardwareStatus = useCallback(() => {
    const [actionCreator, id] = gatewayId != null
      ? [getGatewayWithStatus, gatewayId]
      : [getSensorWithStatus, sensorId];
    dispatch(actionCreator({ id }));
  }, [dispatch, sensorId, gatewayId]);

  const hardwareWithStatusNow = mergeStatusNowToHardware(
    gatewayState || sensorState,
    hardware,
  );

  if (gatewayId != null && gatewayState != null) {
    isLoadingData = gatewayState.loading;
  } else if (sensorId != null && sensorState != null) {
    isLoadingData = sensorState.loading;
  }

  const getTaskDescription = (hardwareObj) => {
    const { hardwareProduct, uuid } = hardwareObj;
    const hardwareProductLabel = hardwareProduct
      && intl.formatMessage(HW_PRODUCT_MESSAGES[hardwareProduct]);
    return (
      <Grid className="job-task-row-grid">
        <Grid.Row>
          {hardwareProductLabel}
        </Grid.Row>
        {uuid && (
          <Grid.Row>
            <Tag color="blue" className="uuid">{uuid}</Tag>
          </Grid.Row>
        )}
      </Grid>
    );
  };

  const renderTaskStatusIcon = status => (
    <>
      {status === TASK_STATUSES.SUCCESS && (
        <Icon color="green" name="check circle" />
      )}
      {status === TASK_STATUSES.FAILED && (
        <Icon color="red" name="times circle" />
      )}
    </>
  );

  const toggleShowDetails = () => {
    setShowDetails(!showDetails);
    if (!showDetails && (sensorId || gatewayId)) {
      fetchHardwareStatus();
    }
  };

  return (
    <Grid.Row className="task-summary-row">
      <Grid.Column>
        <Accordion>
          <Accordion.Title
            active={showDetails}
            onClick={toggleShowDetails}
          >
            <Grid>
              <Grid.Row className="details-row">
                <Grid.Column width={13} verticalAlign="middle">
                  {getTaskDescription(task.hardware)}
                </Grid.Column>
                <Grid.Column width={1} verticalAlign="middle">
                  {renderTaskStatusIcon(task.status)}
                </Grid.Column>
                <Grid.Column width={1} verticalAlign="middle">
                  <Icon name="dropdown" />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Title>
          <Accordion.Content active={showDetails}>
            <HardwareStatusTaskDetails
              hardware={hardwareWithStatusNow}
              isPending={isLoadingData}
              type={type}
              fixingDescription={task.problem && task.problem.name}
              user={userState}
            />
          </Accordion.Content>
        </Accordion>
      </Grid.Column>
    </Grid.Row>
  );
};

JobTaskRow.propTypes = {
  task: TaskType.isRequired,
};

export default JobTaskRow;
